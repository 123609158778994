<template>
  <div>
<!--    <b-card no-body>-->
<!--      <b-card-body>-->
<!--        <b-row>-->
<!--          <b-col-->
<!--            cols="12"-->
<!--            md="4"-->
<!--            class="mb-md-0 mb-2"-->
<!--          >-->
<!--            <b-form-group-->
<!--              :label="$t('labels.title')"-->
<!--              label-for="title"-->
<!--            >-->
<!--              <b-form-input-->
<!--                id="title"-->
<!--                :value="searchFields.title"-->
<!--                @input="inputChange($event, 'title')"-->
<!--              />-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-card-body>-->
<!--    </b-card>-->
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :permissionKey='"notifications"'
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],

  data() {
    return {
      resourceName: 'notification-messages',
      singularName: 'notification-messages',
      searchFields: {
        title: null,
       },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
  // eslint-disable-next-line vue/order-in-components
 }
</script>
